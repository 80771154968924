<script lang="ts" setup>
import SkrButton from '@/components/button/SkrButton.vue'
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import SkrCheckbox from '@/components/SkrCheckbox.vue'

withDefaults(
  defineProps<{
    loading: boolean
    isAdmin?: boolean
  }>(),
  {
    isAdmin: false,
  }
)

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'action', data: Dialogs['business/DialogBusinessDowngraded']['contextData']): void
}>()

const { t } = useI18n()

const isLoading = ref(false)

const isLegalAccepted = ref(false)

const downgrade = () => emit('action', {})
</script>

<template>
  <base-dialog
    data-cy="business_downgraded_dialog"
    max-width="720"
    persistent
    :title="isAdmin ? t('dialog.business.downgrade.admin.title') : t('dialog.business.downgrade.member.title')"
  >
    <v-row class="py-10">
      <v-col>
        <div class="text-h5 text-skribbleu font-weight-bold pb-4">
          {{ t('dialog.business.downgrade.subtitle') }}
        </div>
        <p class="pb-3">
          {{ t('dialog.business.downgrade.text') }}
        </p>
        <skr-checkbox v-model="isLegalAccepted" class="pb-8">
          <i18n-t keypath="global.accept_gtc.label_text" tag="div" for="global.accept_gtc.label_link_text">
            <a :href="t('global.accept_gtc.link_url')" target="_blank" rel="noopener" @click.stop>{{
              t('global.accept_gtc.label_link_text')
            }}</a>
          </i18n-t>
        </skr-checkbox>
        <skr-button size="xl" block :loading="loading" :disabled="isLoading || !isLegalAccepted" @click="downgrade">
          {{ t('dialog.business_deleted_member.button_text') }}
        </skr-button>
      </v-col>
      <v-col v-if="$vuetify.display.mdAndUp">
        <responsive-image source="dialog-company-building" :width="261" :height="272" />
      </v-col>
    </v-row>
    <template #actions>
      <v-spacer />
    </template>
  </base-dialog>
</template>
